import React from 'react';

const Freelancer = React.lazy(() => import('./pages/Freelancer'));
const Contact = React.lazy(() => import('./pages/Contact'));
const LicForm = React.lazy(() => import('./pages/Form/Form'));

const routes = [
  //routes without Layout
  { path: '/', component: Freelancer, isWithoutLayout: true, exact: true },
  { path: '/contact', component: Contact, isTopbarDark: true },
  { path: '/form', component: LicForm, isTopbarDark: true },
];

export default routes;
