import React, { Suspense } from 'react';

import Layout from './components/Layout/';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from 'react-router-dom';

// Import Css
import './assets/css/materialdesignicons.min.css';
import './App.scss';
import './assets/css/style.css';
import './assets/css/colors/default.css';

// Include Routes
import routes from './routes';

function withLayout(WrappedComponent: any, hasDarkTopBar: any) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}
const App: React.FC = () => {
  let Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Router>
        <Suspense fallback={Loader()}>
          <Switch>
            {routes.map((route: any, idx: any) => (
              <Route
                path={route.path}
                exact
                component={withLayout(route.component, route.isTopbarDark)}
                key={idx}
              />
            ))}
          </Switch>
        </Suspense>
      </Router>
    </React.Fragment>
  );
};

export default withRouter(App);
