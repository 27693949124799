import React, { FC, ReactNode, Suspense } from 'react';
import { withRouter } from 'react-router-dom';

// Layout Components
const NavbarPage = React.lazy(() => import('../../pages/NavBarPage'));
const Footer = React.lazy(() => import('./Footer'));

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
type Props = { children: ReactNode };

const Layout: FC<Props> = ({ children }) => (
  <React.Fragment>
    <Suspense fallback={Loader()}>
      {children}
      <NavbarPage />
      <Footer isLight={true} />;
    </Suspense>
  </React.Fragment>
);

// @ts-ignore
export default withRouter(Layout);
